import { Maybe, Query } from "@graphql-types";
import { PageProps, graphql } from "gatsby";
import React, { useEffect, useState } from "react";

import { Button } from "@global";
import { Container } from "@util/standard";
import styled from "styled-components";
import { usePersonalityQuiz } from "@util/hooks";

interface Props extends PageProps {
  data: Query;
}

const StyledButton = styled.div`
  width: 40px;
  height: 40px;
  font-size: 40px;
  cursor: pointer;
  margin: 50px auto;

  background-color: #f5cc78;
  color: white;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;

  p {
    color: white;
    margin: 0;
    font-size: 30px;
    margin-top: 3px;
  }
`;

const PersonalityQuiz = ({ data }: Props) => {
  if (data == null) return null;
  const pageData = data.allSanityPersonalistyQuiz.nodes[0];
  if (pageData == null) return;

  const { questions } = pageData;

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [score, setScore] = useState([]);

  const { updateScore, calculateScore, resultObject } = usePersonalityQuiz();

  const handleSubmit = (
    e: Boolean,
    personalityType: Maybe<string> | undefined,
    questionID: Maybe<string> | undefined,
  ) => {
    setCurrentQuestion(currentQuestion + 1);
    const scoreData = {
      id: questionID,
      personalityType: personalityType,
      score: e,
    };
    updateScore(scoreData);
    // calculateScore();
  };

  return (
    <Container
      flexDirection="column"
      width="40%"
      margin="100px auto"
      justifyContent="center"
      alignItems="center"
      backgroundColor="teal"
      padding="40px"
    >
      {questions && (
        <>
          <p>{questions[currentQuestion]?.question}</p>

          {showTooltip && (
            <Container backgroundColor="mint">
              <p>{questions[currentQuestion]?.tooltip}</p>
            </Container>
          )}
          {questions[currentQuestion]?.tooltip && (
            <StyledButton
              onClick={() => {
                setShowTooltip(!showTooltip);
              }}
            >
              <p>?</p>
            </StyledButton>
          )}
          <Container columnGap="20px">
            <Button
              linkText="Not really me"
              buttonTheme="orange"
              onClick={() => {
                handleSubmit(
                  false,
                  questions[currentQuestion]?.personalityType,
                  questions[currentQuestion]?._key,
                );
              }}
            />
            <Button
              linkText="Sounds like me"
              buttonTheme="teal"
              onClick={() => {
                handleSubmit(
                  true,
                  questions[currentQuestion]?.personalityType,
                  questions[currentQuestion]?._key,
                );
              }}
            />
          </Container>
          {currentQuestion > 0 && (
            <a
              onClick={() => {
                setCurrentQuestion(currentQuestion - 1);
              }}
              style={{ width: "50%", marginTop: "30px" }}
            >
              Back
            </a>
          )}
        </>
      )}
    </Container>
  );
};

export default PersonalityQuiz;

export const query = graphql`
  query quizQuery {
    allSanityPersonalistyQuiz {
      nodes {
        _id
        _key
        title
        questions {
          personalityType
          question
          tooltip
          _type
          _key
        }
      }
    }
  }
`;
